<template>
  <div v-if="render">
    <div>
      <b-modal
        :active.sync="isLoading"
        :width="400"
        :can-cancel="[false, false, false]"
      >
        <div class="modal-card" style="width: auto">
          <section class="modal-card-body">
            <div class="field" style="margin-top: 25px;">
              <p style="margin-bottom: 15px; text-align: center;">
                Carregando informações, por favor, não recarregue a página até
                que o processo seja concluído.
              </p>
              <progress class="progress is-small is-primary" max="100"
                >15%</progress
              >
            </div>
          </section>
        </div>
      </b-modal>
    </div>

    <div class="has-background-bluedark2">

      <b-table
        blueDark2
        :data="accessTable"
        :mobile-cards="true"
        ref="tableAccess"
        detailed
        :default-sort="['name', 'asc']"
        detail-key="id"
        :show-detail-icon="true">
        <!-- custom-detail-row -->

        <template slot-scope="props" slot="header">
          <div v-if="props.column.sortable" class="label-table centralize-row">
            <span v-if="props.column.sortable">
              <b-icon icon="sort" pack="fas" size="is-small"></b-icon>
            </span>
            <p>{{ props.column.label }}</p>
          </div>
          <div v-else class="label-table centralize-row">
            <p>{{ props.column.label }}</p>
          </div>
        </template>

        <template slot-scope="page">

          <b-table-column field="active" label="Ativo">
            <b-switch type="is-success" v-model="page.row.active" @input="toggleFlag('page',page.row)"></b-switch>
          </b-table-column>

          <b-table-column field="name" label="Tela" left>
            {{ page.row.name }}
          </b-table-column>

          <b-table-column field="description" label="Descrição" left>
            {{ page.row.description }}
          </b-table-column>

        </template>

        <template slot="detail" slot-scope="page">

          <tr class="detail">

            <td></td>
            <td colspan="4">

              <b-table
                blueDark
                :data="page.row.pageFunctions"
                ref="tablepageFunctions"
                :default-sort="['name', 'asc']"
                :show-header="false"
              >
                <template slot-scope="pageFunc">

                  <b-table-column field="active" label="Ativo">
                    <b-switch type="is-success" v-model="pageFunc.row.active" @input="toggleFlag('function', page.row, pageFunc.row)"></b-switch>
                  </b-table-column>

                  <b-table-column field="name" label="Funções" left>
                    {{ pageFunc.row.name }}
                  </b-table-column>

                  <b-table-column field="description" label="Descrição" left>
                    {{ pageFunc.row.description }}
                  </b-table-column>

                </template>
              </b-table>
            </td>
          </tr>
        </template>

        <template slot="empty">
            <section class="section">
                <div class="content has-text-grey has-text-centered">
                    <p>
                        <b-icon
                            icon="emoticon-sad"
                            size="is-large">
                        </b-icon>
                    </p>
                    <p>Nenhum Registro.</p>
                </div>
            </section>
        </template>
      </b-table>
    </div>

  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  name: "Acessos",
  data() {
    return {
      render: false,
      isLoading: false,
      accessTable: [],
      flagSelect: {}
    };
  },
  async created() {

    let loader = this.$loading.show()
    this.accessTable = await this.getAllAccess();
    this.render = (this.accessTable)
    loader.hide()
  },
  methods: {
    ...mapActions(["getAllAccess","flagAccess"]),
    async toggleFlag(type, page, pageFunc){

      this.flagSelect = {type, page, pageFunc, access: (type == "page") ? page : pageFunc}

      if (this.flagSelect.type == "page"){
        this.flagSelect.access.pageFunctions.map(f => {
          f.active = this.flagSelect.access.active
        })
      } else {
        this.flagSelect.page.active = this.flagSelect.access.active
        this.flagSelect.page.pageFunctions.map(f => {
          if (f.active){
            this.flagSelect.page.active = f.active
          }
        })
      }

      let active = this.flagSelect.access.active
      let title = (active) ? "Ativar" : "Desativar"
      let msg = (active) ? "será ativada e liberada para o acesso dos usuários." : "será desativada e ficará em manutenção para os usuários."
      let confirm = (active) ? "Ativar" : "Desativar"

      title = title + " " + ((type == 'page') ? "Página" : "Função")
      msg = ((type == 'page') ? "A Página" : "A Função") +" "+ msg

      this.$buefy.dialog.confirm({
        title: title,
        message: '<p>Tem certeza que deseja alterar o flag de ativação?</p><p>'+msg+'</p>',
        confirmText: confirm,
        cancelText: 'Cancelar',
        type: (active) ? 'is-success' : 'is-danger',
        hasIcon: true,
        onCancel: async () => {
          this.flagSelect.access.active = !this.flagSelect.access.active
          if (this.flagSelect.type == "page"){
            this.flagSelect.access.pageFunctions.map(f => {
              f.active = this.flagSelect.access.active
            })
          } else {
            this.flagSelect.page.active = this.flagSelect.access.active
            this.flagSelect.page.pageFunctions.map(f => {
              if (f.active){
                this.flagSelect.page.active = f.active
              }
            })
          }
        }, 
        onConfirm: async () => {

          try {
            this.isLoading = true;

            let response = await this.flagAccess(this.flagSelect);

            if (response.ok){
              if (response.data.data.affectedRows == 1){
                this.$toasted.success("Flag alterado com sucesso!", {
                  duration: 3000,
                  position: "top-center"
                });
              }
            } else {
              console.log(response.statusText);
              this.$toasted.error("Ocorreu um problema ao tentar alterar o flag! Erro: "+response.statusText, {
                duration: 3000,
                position: "top-center"
              });
            }

            this.isLoading = false;

          } catch (err) {
            this.isLoading = false;
            console.log(err);
            this.$toasted.error("Ocorreu um problema ao tentar alterar o flag!", {
              duration: 3000,
              position: "top-center"
            });
          }

        }
      })

    }
  }
};
</script>
